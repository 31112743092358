import React from 'react';
import { MainLayout } from '../../components/Layout';
import PartnersList from '../../components/Partners/PartnersList';
import { Link, withPrefix } from 'gatsby';
import imgBg from '../../assets/img/home/bg-wave.png';
import imgMobile from '../../assets/img/partners/mobile-discussions.png';
import imgIconChat from '../../assets/img/partners/icon-chat.svg';
import imgIconUp from '../../assets/img/partners/icon-up.svg';
import imgIconLove from '../../assets/img/partners/icon-love.svg';
import ReferencesSection from '../../components/Partners/ReferencesSection';
import Seqens from '../../assets/img/references/sequens.png';
import VieHabitat from '../../assets/img/references/1001-vies-habitat.png';
import ActionLogement from '../../assets/img/references/ActionLogement.png';
import ICF from '../../assets/img/references/ICF.png';
import Vilogia from '../../assets/img/references/vilogia.png';

export default () => {
  const referenceList = [
    {
      icon: Seqens,
      content: '',
    },
    {
      icon: VieHabitat,
      content: '',
    },
    {
      icon: ActionLogement,
      content: '',
    },
    {
      icon: ICF,
      content: '',
    },
    {
      icon: Vilogia,
      content: '',
    },
  ];

  return (
    <MainLayout
      siteTitle={`Bailleurs - Découvrez nos offres`}
      siteDescription={`Découvrez nos offres déstinées aux bailleurs`}
    >
      <PartnersList selectedPartner="bailleurs" />

      <div
        className="bg-wave"
        style={{ backgroundImage: `url(${withPrefix(imgBg)})` }}
      >
        <section className="container mt-5">
          <div className="row">
            <div className="col-6 text-align-center hidden-sm">
              <img
                src={imgMobile}
                alt="Syndic de copropriété"
                className="max-width-400-lg"
              />
            </div>

            <div className="col-4">
              <div className="pl-5 pr-5">
                <div className="roboto font-size-xl bold pt-10">
                  Remontée d’incidents
                </div>
                <div className="text-color-purple-grey pt-5">
                  Pilotez la résolution des incidents de vos immeubles grâce à
                  un tableau de bord récapitulatif
                </div>

                <div className="roboto font-size-xl bold pt-10">
                  Diffusion d’informations
                </div>
                <div className="text-color-purple-grey pt-5">
                  Communiquez la bonne information, à la bonne personne, au bon
                  moment, grâce à des groupes de diffusion spécifique
                </div>

                <div className="roboto font-size-xl bold pt-10">
                  Animation locale
                </div>
                <div className="text-color-purple-grey pt-5">
                  Valoriser la proximité en enrichissant la vie de quartier
                  grâce à des animateurs locaux
                </div>

                <div className="roboto font-size-xl bold pt-10">
                  Gestion des espaces partagés
                </div>
                <div className="text-color-purple-grey pt-5">
                  Encouragez la vie en communauté grâce à une gestion simple des
                  espaces de convivialité de vos résidences
                </div>

                <div className="ml-auto mr-auto pt-15 text-align-center hidden-sm">
                  <Link
                    to="/contact/"
                    state={{
                      contactType: 'partner',
                      contactSubType: 'bailleurs',
                    }}
                    className="btn"
                  >
                    Nous contacter
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row hide show-md">
            <div className="col-12 pt-5 pl-6-md pr-6-md text-align-center">
              <img src={imgMobile} alt="Syndic de copropriété" />
            </div>
          </div>

          <div className="row pb-10 hide show-md">
            <div className="col-12 ml-auto mr-auto text-align-center">
              <Link
                to="/contact/"
                state={{ contactType: 'partner', contactSubType: 'bailleurs' }}
                className="btn"
              >
                Nous contacter
              </Link>
            </div>
          </div>
        </section>
      </div>

      <section className="container mt-10 partners-advantages-list">
        <ul className="text-align-center">
          <li className="mb-4">
            <div className="advantage-icon">
              <img src={imgIconChat} alt="Communiquez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Communiquez</strong> efficacement avec les résidents
            </div>
          </li>
          <li className="mb-4 ml-10 mr-10">
            <div className="advantage-icon">
              <img src={imgIconUp} alt="Valorisez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Valorisez</strong> le travail de vos gestionnaires et
              régisseurs
            </div>
          </li>
          <li className="mb-4">
            <div className="advantage-icon">
              <img src={imgIconLove} alt="Apaisez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Apaisez</strong> les relations entre les parties prenantes
            </div>
          </li>
        </ul>
      </section>

      <ReferencesSection referenceList={referenceList} />
    </MainLayout>
  );
};
